/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { MailEndpointApiService } from './services/mail-endpoint-api.service';
import { NotificationsEndpointApiService } from './services/notifications-endpoint-api.service';
import { CorsApiService } from './services/cors-api.service';
import { ApplicationsEndpointApiService } from './services/applications-endpoint-api.service';
import { TemplatesEndpointApiService } from './services/templates-endpoint-api.service';
import { SmsEndpointApiService } from './services/sms-endpoint-api.service';
import { PushEndpointApiService } from './services/push-endpoint-api.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    MailEndpointApiService,
    NotificationsEndpointApiService,
    CorsApiService,
    ApplicationsEndpointApiService,
    TemplatesEndpointApiService,
    SmsEndpointApiService,
    PushEndpointApiService,
    ApiConfiguration
  ],
})
export class NotificationsApiModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<NotificationsApiModule> {
    return {
      ngModule: NotificationsApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: NotificationsApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('NotificationsApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
