/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { PostNotificationResponse } from '../models/post-notification-response';


/**
 * This lists and explains endpoints that relate to push notifications.
 */
@Injectable({
  providedIn: 'root',
})
export class PushEndpointApiService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation postPush
   */
  static readonly PostPushPath = '/push';

  /**
   * sends a push notification to the user represented by the given token and applicationId.
   *
   * sends a push notification to the user represented by the given token and applicationId
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postPush()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postPush$Response(params: {
    context?: HttpContext
    body: {
'media'?: 'browser' | 'android' | 'ios';
'applicationId'?: string;
'userId'?: string;
'template'?: 'INVITATION' | 'REPORT_FILE' | 'MARKED_PRIVATE' | 'CHANGE_ROLE';
}
  }
): Observable<StrictHttpResponse<PostNotificationResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PushEndpointApiService.PostPushPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PostNotificationResponse>;
      })
    );
  }

  /**
   * sends a push notification to the user represented by the given token and applicationId.
   *
   * sends a push notification to the user represented by the given token and applicationId
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postPush$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postPush(params: {
    context?: HttpContext
    body: {
'media'?: 'browser' | 'android' | 'ios';
'applicationId'?: string;
'userId'?: string;
'template'?: 'INVITATION' | 'REPORT_FILE' | 'MARKED_PRIVATE' | 'CHANGE_ROLE';
}
  }
): Observable<PostNotificationResponse> {

    return this.postPush$Response(params).pipe(
      map((r: StrictHttpResponse<PostNotificationResponse>) => r.body as PostNotificationResponse)
    );
  }

  /**
   * Path part for operation postToken
   */
  static readonly PostTokenPath = '/token';

  /**
   * registers a token for the user represented by the given applicationId and userId.
   *
   * registers a token for the user represented by the given applicationId and userId
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postToken()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postToken$Response(params: {
    context?: HttpContext
    body: {
'media'?: 'browser' | 'android' | 'ios';
'applicationId'?: string;
'token'?: string;
'userId'?: string;
}
  }
): Observable<StrictHttpResponse<PostNotificationResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PushEndpointApiService.PostTokenPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PostNotificationResponse>;
      })
    );
  }

  /**
   * registers a token for the user represented by the given applicationId and userId.
   *
   * registers a token for the user represented by the given applicationId and userId
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postToken$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postToken(params: {
    context?: HttpContext
    body: {
'media'?: 'browser' | 'android' | 'ios';
'applicationId'?: string;
'token'?: string;
'userId'?: string;
}
  }
): Observable<PostNotificationResponse> {

    return this.postToken$Response(params).pipe(
      map((r: StrictHttpResponse<PostNotificationResponse>) => r.body as PostNotificationResponse)
    );
  }

  /**
   * Path part for operation deleteToken
   */
  static readonly DeleteTokenPath = '/token';

  /**
   * deletes a token that represents a user who is using a client application that supports push notifications.
   *
   * deletes a token that represents a user who is using a client application that supports push notifications
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteToken()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deleteToken$Response(params: {
    context?: HttpContext
    body: {
'media'?: 'browser' | 'android' | 'ios';
'token'?: string;
}
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, PushEndpointApiService.DeleteTokenPath, 'delete');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * deletes a token that represents a user who is using a client application that supports push notifications.
   *
   * deletes a token that represents a user who is using a client application that supports push notifications
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteToken$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deleteToken(params: {
    context?: HttpContext
    body: {
'media'?: 'browser' | 'android' | 'ios';
'token'?: string;
}
  }
): Observable<void> {

    return this.deleteToken$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation postApp
   */
  static readonly PostAppPath = '/app';

  /**
   * adds an application configuration that can send push notifications to a user.
   *
   * adds an application configuration that can send push notifications to a user
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postApp()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postApp$Response(params: {
    context?: HttpContext
    body: {
'apiKey'?: string;
'authDomain'?: string;
'projectId'?: string;
'storageBucket'?: string;
'messagingSenderId'?: string;
'appId'?: string;
'measurementId'?: string;
'credential'?: {
'projectId'?: string;
'clientEmail'?: string;
'privateKey'?: string;
};
}
  }
): Observable<StrictHttpResponse<PostNotificationResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PushEndpointApiService.PostAppPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PostNotificationResponse>;
      })
    );
  }

  /**
   * adds an application configuration that can send push notifications to a user.
   *
   * adds an application configuration that can send push notifications to a user
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postApp$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postApp(params: {
    context?: HttpContext
    body: {
'apiKey'?: string;
'authDomain'?: string;
'projectId'?: string;
'storageBucket'?: string;
'messagingSenderId'?: string;
'appId'?: string;
'measurementId'?: string;
'credential'?: {
'projectId'?: string;
'clientEmail'?: string;
'privateKey'?: string;
};
}
  }
): Observable<PostNotificationResponse> {

    return this.postApp$Response(params).pipe(
      map((r: StrictHttpResponse<PostNotificationResponse>) => r.body as PostNotificationResponse)
    );
  }

  /**
   * Path part for operation deleteApp
   */
  static readonly DeleteAppPath = '/app';

  /**
   * deletes an application configuration that can send push notifications to a user.
   *
   * deletes an application configuration that can send push notifications to a user
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteApp()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deleteApp$Response(params: {
    context?: HttpContext
    body: {
'media'?: 'browser' | 'android' | 'ios';
'token'?: string;
}
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, PushEndpointApiService.DeleteAppPath, 'delete');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * deletes an application configuration that can send push notifications to a user.
   *
   * deletes an application configuration that can send push notifications to a user
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteApp$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deleteApp(params: {
    context?: HttpContext
    body: {
'media'?: 'browser' | 'android' | 'ios';
'token'?: string;
}
  }
): Observable<void> {

    return this.deleteApp$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
