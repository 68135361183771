import { Injectable } from '@angular/core';
import { some, get, every, forEach } from 'lodash-es';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PermissionsApiService } from '@shure/cloud/shared/permissions/data-access';

export interface ShPermissionsAggregate {
	$and?: string[];
	$or?: string[];
}

@Injectable({
	providedIn: 'root'
})
export class PermissionsService {
	private userSession!: { string: boolean };
	public userRole!: string;

	constructor(private apiPermissionsService: PermissionsApiService) {}

	public getPermissions$(): Observable<{ string: boolean }> {
		return this.apiPermissionsService.getPermissions$Response().pipe(
			map((response) => {
				const responseData = JSON.parse(<string>(<unknown>response.body));
				this.userSession = responseData.body[0]?.permissions;
				return responseData.body[0]?.permissions;
			})
		);
	}
	public getAdminPermissions$(): Observable<{ string: boolean }> {
		return this.apiPermissionsService.getAdminPermissions$Response().pipe(
			map((response) => {
				const responseData = JSON.parse(<string>(<unknown>response.body));
				this.userSession = responseData.body[0]?.permissions;
				this.userRole = responseData.body[0]?.role;
				return responseData.body[0]?.permissions;
			})
		);
	}
	public testPermissions(requiredPermissions: string | ShPermissionsAggregate): boolean {
		if (!this.userSession) {
			return false;
		}

		if (!requiredPermissions) {
			return false;
		}

		return typeof requiredPermissions === 'string'
			? this.testPermission(requiredPermissions)
			: this.testManyPermissions(requiredPermissions);
	}

	public testPermission(requiredPermission: string): boolean {
		return get(this.userSession, requiredPermission) === true;
	}

	private testManyPermissions(requiredPermissions: ShPermissionsAggregate): boolean {
		let hasAccessToFeature = false;
		let hasAccess = false;
		const combination: boolean[] = [];

		if (requiredPermissions.$or) {
			forEach(requiredPermissions.$or, (feature: string) => {
				hasAccessToFeature = get(this.userSession, feature) === true;
				combination.push(hasAccessToFeature);
			});
			hasAccess = some(combination, Boolean);
		} else if (requiredPermissions.$and) {
			forEach(requiredPermissions.$and, (feature: string) => {
				hasAccessToFeature = get(this.userSession, feature) === true;
				combination.push(hasAccessToFeature);
			});
			hasAccess = every(combination, Boolean);
		}

		return hasAccess;
	}
}
