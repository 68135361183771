import { AppEnvironment } from '@shure/cloud/shared/utils/config';

import { environment as devEnvironment } from './environment.dev';

export const environment: AppEnvironment = {
	...devEnvironment,
	sysApi: {
		url: 'https://durantc-system-api.dev.shure.services/graphql',
		apiKey: '',
		useWsTransport: true
	}
};
